<template>
  <div>
    <Card style="width: 99%">
      <p slot="title">
        <Icon type="ios-information-circle" />
        基础信息
      </p>
      <Form :model="seeTweetData" inline :label-colon="true">
        <FormItem label="作者姓名">
          <p>{{ seeTweetData.createName || "无" }}</p>
        </FormItem>
        <FormItem label="推文类型">
          <p>{{ seeTweetData.type || "无" }}</p>
        </FormItem>
        <FormItem label="标题" style="width: 100%">
          <p>{{ seeTweetData.title || "无" }}</p>
        </FormItem>
        <FormItem label="内容摘要" style="width: 100%">
          <p>{{ seeTweetData.digest || "无" }}</p>
        </FormItem>
        <FormItem label="原文地址">
          <a
            target="_blank"
            :href="seeTweetData.contentSourceUrl"
            style="word-wrap: break-word"
            v-if="seeTweetData.contentSourceUrl"
            >{{ seeTweetData.contentSourceUrl }}</a
          >
          <p v-else>无</p>
        </FormItem>
        <FormItem label="首图" style="width: 100%">
          <img
            :src="seeTweetData.thumbUrl"
            alt=""
            v-if="seeTweetData.thumbUrl"
          />
          <p v-else>无</p>
        </FormItem>
        <FormItem label="首图地址" style="width: 100%">
          <a
            target="_blank"
            :href="seeTweetData.thumbUrl"
            style="word-wrap: break-word"
            v-if="seeTweetData.thumbUrl"
            >{{ seeTweetData.thumbUrl }}</a
          >
          <p v-else>无</p>
        </FormItem>
      </Form>
    </Card>
    <Card style="width: 99%">
      <p slot="title">
        <Icon type="md-albums" />
        详情描述
      </p>
      <Form :model="seeTweetData" inline :label-colon="true">
        <FormItem label="发布内容">
          <div v-html="seeTweetData.content || '无'"></div>
        </FormItem>
      </Form>
    </Card>
    <Card style="width: 99%">
      <p slot="title">
        <Icon type="md-options" />
        扩展信息
      </p>
      <Form :model="seeTweetData" inline :label-colon="true">
        <FormItem label="是否显示封面">
          <Radio :value="true" v-if="seeTweetData.showCoverPic">是</Radio>
          <Radio :value="true" v-else>否</Radio>
        </FormItem>
        <FormItem label="是否原创">
          <Radio :value="true" v-if="seeTweetData">是</Radio>
          <Radio :value="true" v-else>否</Radio>
        </FormItem>
        <FormItem label="是否打开评论">
          <Radio :value="true" v-if="seeTweetData.original">是</Radio>
          <Radio :value="true" v-else>否</Radio>
        </FormItem>
        <FormItem label="是否粉丝才可评论">
          <Radio :value="true" v-if="seeTweetData.onlyFansCanComment">是</Radio>
          <Radio :value="true" v-else>否</Radio>
        </FormItem>
      </Form>
    </Card>
    <Card style="width: 99%">
      <p slot="title">
        <Icon type="md-checkbox" />
        提交信息
      </p>
      <Form :model="seeTweetData" inline :label-colon="true">
        <FormItem label="编辑人">
          <p>{{ seeTweetData.createName || "无" }}</p>
        </FormItem>
        <FormItem label="编辑时间">
          <p>{{ seeTweetData.createTime || "无" }}</p>
        </FormItem>
        <FormItem label="审核人">
          <p>{{ seeTweetData.reviewName || "无" }}</p>
        </FormItem> 
        <FormItem label="审核时间">
          <p>{{ seeTweetData.reviewTime || "无" }}</p>
        </FormItem>
        <FormItem label="发布人">
          <p>{{ seeTweetData.publishName || "无" }}</p>
        </FormItem>
        <FormItem label="发布时间">
          <p>{{ seeTweetData.publishTime || "无" }}</p>
        </FormItem>
      </Form>
    </Card>
  </div>
</template>

<script>
import { format } from "@/utils/formatTime.js";
export default {
  props: ["infoId"],
  data() {
    return {
      seeTweetData: {},
      tweetTypeList: []
    };
  },
  async created() {
    //获取查询类型
    await this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "AGGRE_APPLICATION_POSE_TYPE",
    }).then((res) => {
      if (res.code == 200 && res.dataList) {
        this.tweetTypeList = res.dataList;
      } else {
        this.$Message.error({
          background: true,
          content: "获取推文类型失败",
        });
      }
    });
    this.$get("/voteapi/api/pc/aggre/application/selectApplicationPoseById", {
      poseId: this.infoId,
    })
      .then((res) => {
        if (res.code == 200 && res.data) {
          this.seeTweetData = res.data;
          if(this.seeTweetData.type) {
              this.tweetTypeList.map(item => {
                  if(item.dictKey == this.seeTweetData.type) {
                      this.seeTweetData.type = item.dictValue
                  }
              })
          }
          if(this.seeTweetData.createTime) {
             this.seeTweetData.createTime =  format(this.seeTweetData.createTime,"yyyy-MM-dd HH:mm:ss");
          }
          if(this.seeTweetData.reviewTime) {
             this.seeTweetData.reviewTime =  format(this.seeTweetData.reviewTime,"yyyy-MM-dd HH:mm:ss");
          }
          if(this.seeTweetData.publishTime) {
             this.seeTweetData.publishTime =  format(this.seeTweetData.publishTime,"yyyy-MM-dd HH:mm:ss");
          }

        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      })
      .catch((err) => {
        this.$Message.error({
          background: true,
          content: "获取推文信息失败",
        });
      });
  },
};
</script>
    
<style scoped lang='less'>
.ivu-form-item {
  width: 32%;
}
.ivu-card {
  margin-bottom: 20px;
}
</style>